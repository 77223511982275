.login-page {
  background: var(--color-1);
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100%;

  .left-panel {
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      height: min(100px, 100%);
    }

    .app-name {
      color: var(--color-3);
      font-size: 12px;
      padding-top: 20px;
      text-align: center;
    }
  }

  .right-panel {
    width: 50%;
    background: var(--color-white);
    padding: 100px;
    height: 100vh;
    display: grid;
    place-items: center;

    form {
      .title {
        text-align: center;
        font-weight: 400;
        font-size: 30px;
      }

      .subtitle {
        text-align: left;
        font-weight: 400;
        font-size: 1.25em;
        padding-top: 25px;
      }

      p {
        text-align: center;
        font-weight: 300;
        padding-top: 10px;

        &.disclaimer {
          text-align: left;
          font-size: 0.75em;
        }
      }

      label {
        margin-top: 10px;
        margin-bottom: 0px;
      }

      input {
        height: 50px;
      }

      button {
        margin-top: 20px;
        width: 100%;
      }

      #sms-button {
        margin: 0;
      }

      #email-button {
        margin: 0 16px 0 0;
      }
    }
  }
}
