.unlock-modal {
    backdrop-filter: blur(5px);
    z-index: 10000 !important;

    .modal-footer {
        border-top: unset;
    }
    .sign-out-button {
        padding: 8px !important;
        text-transform: uppercase;
        font-size: var(--font-size-xs);
        font-weight: 800;
        border-radius: 0;
    }
}
