.autocomplete-component{
  overflow-y: auto;
  padding: 10px 5px 5px 5px;
  border-width: 0px 1px 1px;
  border-style: solid;
  border-color: var(--color-borders);
  border-image: initial;
  list-style: none;
  margin-top: 0px;
  min-height: 40px;
  max-height: 170px;
  width: 100%;
  position: relative;
  .suggestions {
    display:none;
		position:absolute;
		min-width:140px;
    &--list{
      list-style-type: none;
      margin-bottom: 2px;
      padding: 1px 4px;
      transition: all .2s ease;
      &:nth-child(1) {
        color: var(--color-2);
      }
    }
    &--list:hover{
      background-color: var(--color-3);
      color: var(--color-1);
      cursor: pointer;
      font-weight: 500;
      list-style-type: none;
      cursor: pointer;
      transition: all .2s ease;
    }
    &--list-active,
    &--list-active:hover {
      color: var(--color-white);
      background-color: var(--color-6);
      cursor: pointer;
      font-weight: 500;
      list-style-type: none;
      padding: 2px 4px;
    }
  }
  .suggestions li:not(:last-of-type) {
    margin-top: 1rem;
    border-bottom: 1px solid var(--color-2);
  }
}