// default .ag-tooltip styles
.custom-tooltip {
  box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
  background-color: #f8f8f8;
  color: #181d1f;
  padding: 6px;
  border: solid 1px;
  border-color: #babfc7;
  border-radius: 3px;

  .custom-tooltip-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .custom-tooltip-title {
      text-transform: uppercase;
      color: var(--color-2);
      font-weight: 600;
    }
  }
}