.drag_things_to_boxes .box {
	width: 100%;
	min-height: 276px;
	margin: 10px 80px 0 0;
	background: white;
}

.btn-group .btn-primary {
	background-color: #3f8bd1 !important;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-color: rgba(0,0,0,.09);
	font-size: 12px;
}

.add-element {
  margin: 20px 0px;
}

.box_item_component {
  width: 100%;
  border: 1px solid transparent;
}

.component-title {
  display: flex;
  align-items: center;
}

.box_item_component .outer {
  border-top: none;
  width: 100%;
}

.box_item_component .highlighted .outer {
  border-top: 40px solid #ddd;
}

.box_item_component .item {
  padding: 8px 0px;
  width: 100%;
  display: flex;
  border-bottom: 1px dotted lightgrey;
}

.box_item_component .grabber {
  font-size: 14px;
  font-weight: bold;
  padding: 0px 10px;
  display: inline;
}

.temp {
  padding: 10px;
  margin: 5px;
  background: grey;
  color: white;
  font-weight: bold;
  width: 150px;
}

.button-tile {
  padding: 10px !important;
  font-size: 10px;
  width: 100px;
  margin-bottom: 10px;

  .small-size {
    font-size: 10px;
    text-align: center;
  }
}

.reach-form-label {
  padding-right: 16px;
  font-size: 12px;
  display: inline;
}

.reach-question-with-padding {
  display: flex;
  // margin-left: 15px;
  margin-top: 5px !important;
}

.reach-app-input {
  font-style: italic;
  cursor: pointer;
  font-size: 12px;
  margin-right: 5px;
  border: 0 !important;
  box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
  
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(0,139,139,.7) !important;
    opacity: 1; /* Firefox */
    font-style: italic;
  }

  &:empty {
    &:before {
      content: attr(data-placeholder);
      display: inline-block;
    }
  }
}

.required {
  color: rgba(0,139,139,.7);
}

.optional {
  color: #bbb;
}

.hiddenRow {
  text-decoration: line-through;
  color: lightgray !important;
}

.ddcontainer {
  display: block !important;
}

.card {
  margin: 10px 10px 10px 0px;
  border-radius: 0px !important;
  
  .title-container {
    font-weight: 300;
    font-size: 18px;
    white-space: nowrap;
    border-bottom: 1px solid rgba(0,0,0,.15);
    padding-bottom: 15px;
  }
}

.action-links {
  font-size: 11px;

  .btn-sm {
    font-size: 11px !important;
  }
}

.rule-box {
  border: 1px solid #D3D3D3;
  padding: 10px 15px;
  font-size: 12px;
  margin-bottom: 5px;
}