body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  --color-1: rgba(0, 0, 0, 0.7); // dark-grey
  --color-2: #808080; // grey;
  --color-3: #D3D3D3; // lightgrey;
  --color-3-lighter: #eeeeee; // lightergrey;
  --color-borders: #BABFC7; // silver sand

  --color-4: #025A86; // sapphire blue
  --color-5: #103366; // prussian blue
  --color-6: #17A2B8; // pacific blue

  --color-7: #842029; // boostrap danger alert text
  --color-8: #f8d7da; // boostrap danger alert background
  --color-9: #f5c2c7; // boostrap danger alert borders

  --color-white: #FFFFFF; // white;
  --color-black: #000000; // black;

  --color-active: #198754; // green;
  --color-inactive: #F6BE00; // orange yellow
  --color-archived: #FF8C00; // dark orange
  --color-deleted: #DC3545; // rusty red

  --color-active-bg: rgba(6, 128, 0, 0.19);
  --color-deleted-bg: rgba(128, 0, 0, 0.19);

  --font-size-xl: 24px;
  --font-size-lg: 16px;
  --font-size-md: 14px;
  --font-size-sm: 12px;
  --font-size-xs: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  max-height: 100vh;
  overflow-y: hidden;
}

h4 {
  font-weight: 400 !important;
}

.flex-row {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.flex-row-100 {
  display: flex;
  flex-direction: row;
  height: 100%;

  .flex-1 {
    flex: 1;

    .main-container {
      width: 100%;
      height: 100%;
      padding: 20px 0px 60px;

      .grid-container {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.flex-column {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.flex-padding-40 {
  flex: 1;
  padding: 40px;
  overflow-y: auto;
}

.height-100 {
  height: 100%;
}

.text-align-right {
  text-align: right;
}

.container-padding-40 {
  overflow: hidden;
  padding: 40px;
  width: 100%;
}

.container-padding-top-20 {
  overflow: hidden;
  padding-top: 20px;
  width: 100%;
}

.ag-container {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 20px 0px 60px;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.label {
  font-weight: bold;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.float-right {
  float: right;
  button {
    span {
      padding-left: 5px;
    }
  }
}

.badge-info {
  background: var(--color-6);
  border-radius: 8px !important;
  margin-left: 5px;
}

.btn-primary {
  background: var(--color-5) !important;
  border-color: var(--color-5) !important;
}

.confirm-toast {
  padding: 4px 8px;

  h5 {
    color: var(--color-black);
    margin-bottom: 10px;
  }

  .toast-message {
    font-size: .875em;
  }

  .toast-buttons {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    opacity: 2;

    .btn {
      margin-left: 10px;
      font-weight: bold;
      border: 0px;
    }

    .outline-secondary {
      color: var(--color-5);
      &:hover {
        color: var(--color-white);
      }
    }

    .primary {
      color: var(--color-white);
    }
  }
}

.hide {
  display: none;
}

.clear-both {
  clear: both;
}

.disabled-link {
  pointer-events: none;
  cursor: none !important;
  opacity: 0.5;
}

.record-info {
  font-size: 12px;
  color: var(--color-2);
}

.react-date-picker__calendar {
  z-index: 999;
}

.tag {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #FFF;
  --bs-background-color: #008080;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  background-color: var(--bs-background-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.run-history {
  .list-group-item {
    margin-bottom: 14px;

    .file-icon {
      margin-right: 6px;
    }
    .bg-danger {
      background-color: var(--color-8) !important;
      border: 1px solid var(--color-9) !important;
      color: var(--color-7);
      font-weight: 500;
    }
  }
}

.react-date-picker__calendar {
  z-index: 999;
}

.events-process-overview {
  background: #EEE;
  padding: 20px;
  color: var(--color-2);
}

.interfaces-date-divider {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 36px 0 12px;
  width: 100%;

  .divline {
    background-color: var(--color-3);
    height: 1px;
    width: 100%;
  }
  .date {
    background-color: var(--color-white);
    color: var(--color-2);
    font-weight: 600;
    font-size: 12px;
    padding: 6px 10px;
    transform: translateY(-50%);
    position: sticky;
    top: 0;
  }
}

.modal-body {
  max-height: 80vh;
  overflow-y: auto;
}

.mr-6 {
  margin-right: 50%;
}

.centered-message {
  padding: 200px 20px;
  margin-top: 20px;
  text-align: center;
  color: gray;

  .message {
    margin-top: 20px;
    font-weight: 200;
    font-size: 24px;
  }
}

.input-group > .form-control:not(:focus).is-invalid,
.input-group > .form-floating:not(:focus-within).is-invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-control:not(:focus):invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.was-validated .input-group > .form-select:not(:focus):invalid {
  z-index: 0 !important;
}

.reach-background {
  padding: 20px;
  background: #e8ebf0;
  margin: 30px 0px !important;
  border: 1px solid grey;
}

.dropdown-toggle::after {
  display: none !important;
}

.tabs-container {
  display: grid;
  grid-template-rows: max-content auto;
  height: calc(100vh - 203px);

  .tab-content {
    padding-top: 20px;
  }
}

.description-cell {
  line-height: 20px;
  font-size: 12px;
  padding-top: 10px;

  .description-cell-title {
    text-transform: uppercase;
    color: var(--color-2);
    font-weight: 600;
  }

  .description-cell-subtitle {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
