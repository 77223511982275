.usagesContent {
    .usage-list-year{
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      &--item{
        margin: 0;
        font-weight: 700;
        color: var(--color-4);
      }
      &--line{
        width: 85%;
        height: 1px;
        border-top: solid var(--color-4) 2px;
      }
    }
    &--dropdown{
      display: flex;
      .dropdownMonthsLabel{
        padding-top: 10px;
        padding-right: 10px;
        font-weight: bold;
      }
      .dropdownMonthsSelect{
        font-style: oblique;
      }
    }
}

.ag-header-cell.text-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}

.ag-cell-focus,.ag-cell-no-focus{
  border-color: transparent !important;
}
/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus{
  border-color: transparent !important;
  outline: none;
}
