.profile {
  overflow: auto !important;

  h2 {
    margin-bottom: 20px;
  }

  .field{
    margin-bottom: 20px;
  }

  .status {
    font-weight: 800;
  }

  .switch {
    padding-top: 10px;
  }

  .card {
    padding: 20px;
    margin-bottom: 20px;

    .card-title {
      margin-bottom: 20px !important;
    }
  }

  .profile-info {
    padding-bottom: 40px;
    font-size: 12px;
    color: var(--color-2);
  }

  .float-right {
    button {
      span {
        padding-left: 5px;
      }
    }
  }

  input,
  select {
    font-size: 14px;
  }
}
