.side-panel {
  position: absolute;
  background-color: var(--color-white);
  height: calc(100vh - 70px);
  top: 70px;
  width: 500px;
  right: 0;
  overflow-x: hidden;
  border-left: 1px solid var(--color-3);
  box-shadow: -10px 0px 52px -19px var(--color-3);
  animation: appearFromRight .3s ease-in-out forwards;
  display: grid;
  grid-template-rows: max-content auto;
  z-index: 900;

  &.hide {
    animation: hideToRight .3s ease-in forwards;
  }

  &.loading > * {
    filter: blur(4px);
    transition: opacity .15s ease;
    transition: .15s filter linear;
    opacity: .5;
    pointer-events: none;
  }

  .side-panel-header {
    border-bottom: 1px solid var(--color-3);
    padding: 24px 40px;
    width: 100%;

    small {
      color: var(--color-2);
    }

    h4 {
      text-transform: capitalize;
    }
  }

  .side-panel-content {
    padding-right: 42px;
    padding-left: 40px;
    overflow-x: hidden;
    overflow-y: auto;

    &.no-tabs {
      padding: 24px 40px;
    }
  }

  svg.close-icon {
    position: absolute;
    right: 34px;
    top: 26px;
    fill: var(--color-2);
    cursor: pointer;
    transition: all .1s linear;

    &:hover {
      fill: unset;
    }
  }

  .option {
    padding: 20px;
    border: 1px solid var(--color-3);
    margin-bottom: 8px;
  }

  .tabs-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 203px);

    .tab-content {
      padding-top: 24px;
    }
  }

  .form-container {
    padding-bottom: 48px;

    .buttons {
      z-index: 99;
      margin-top: 200px;
      text-align: right;
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 16px 42px;
      background: var(--color-3-lighter);
    }
  }

}

@keyframes appearFromRight {
  0% {
    transform: translateX(120%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes hideToRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(120%);
    opacity: .5;
  }
}

.alert-icon-padding {
  padding: 0 var(--bs-alert-padding-x);
  margin: 0;
}
