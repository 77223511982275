.client-nav {
  background:var(--color-3);
  width: 310px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .disabled-link {
    opacity: 0.2;
  }

  .client {
    background: var(--color-5);
    color:var(--color-white);
    padding: 40px;
    font-size: 12px;
    font-weight: 800;

    .name {
      font-weight: 300;
      font-size: 22px;
      line-height: 22px;
      margin-bottom: 10px;
    }
  }

  .tabs {
    flex: 1;
    height: 100%;
    overflow-y: auto;

    div {
      margin-bottom: 20px;
    }

    .nav-title {
      margin-left: 10px;
    }

    a {
      color: var(--color-2);
      text-decoration: none;
      font-weight: 800;

      &:hover {
        opacity: 1;
        color: var(--color-1);
      }
    }

    .active {
      opacity: 1;
      color: var(--color-1);
    }

    .tab-content {
      padding: 40px;

      .info {
        color: var(--color-4);
      }
    }
  }

  .nav-tabs {
    .nav-item {
      .nav-link {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 10px;
        background: var(--color-4);
        border-radius: 0px;
        color: white;
        border: 0px;
      }

      .nav-link.active {
        background: var(--color-6) !important;
      }
    }
  }

  .footer {
    padding: 20px 40px;
    color: var(--color-2);
    font-size: 10px;
    font-weight: 800;
    text-align: center;
  }
}

.tab-scrollable {
  height: 40vh;
  overflow-y: scroll;
}
.ag-dnd-ghost.ag-unselectable.ag-theme-alpine {
    width: 100px;
}
