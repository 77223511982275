.home-page {
  height: calc(100vh - 70px);
  display: grid;
  grid-template-rows: calc(100% - 80px) 80px;
  overflow: auto;

  .content {
    height: 100%;
    padding: 40px;
    overflow: auto;

    a {
      text-decoration: none;
    }

    .col-md-4 {
      margin-bottom: 20px;

      .card-box {
        min-height: 200px;
        background: var(--color-3);
        color: var(--color-2);
        text-align: center;
        border-radius: 6px;
        font-size: 16px;
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--color-3);
        transition: all .2s ease;

        &:hover {
          border-color: var(--color-4);
          color: var(--color-4);
          cursor: pointer;
        }

        .title {
          text-transform: uppercase;
          font-weight: 800;
          font-size: 16px;
          line-height: 16px;
          padding-top: 10px;
        }

        .description {
          font-size: 12px;
          padding-top: 10px;
        }
      }
    }
  }

  .footer {
    height: 80px;
    padding: 20px 40px;
    background: var(--color-3);
    color: var(--color-2);
    text-align: center;
    font-size: 12px;
    font-weight: 800;
  }
}
