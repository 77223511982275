.form-container {
  clear: both;
  font-size: var(--font-size-md);

  .header {
    display: flex;
    flex-direction: row;

    .section-title {
      color: grey;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 0;
    }
  
    .action-buttons {
      float: right;
    }
  }


  .title {
    font-size: var(--font-size-xl);
    text-transform: uppercase;
    font-weight: 200;
  }

  .description {
    padding-bottom: 20px;
  }

  .label {
    font-weight: bold;
    span {
      margin-left: 2px;
      color: var(--color-deleted);
    }
  }

  .css-16xfy0z-control {
    background: #E9ECEF;

    .css-olqui2-singleValue {
      color: #212529;
    }
    
    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }
  }

  .basic-single {
    .css-13cymwt-control {
      font-size: 14px;
      border: 1px solid #ced4da;
      border-radius: .375rem;
  
      div[id^="react-select-"][id$="-placeholder"] {
        font-size: 14px !important;
      }
    }
  }

  &.validated {
    .basic-single {
      .required {
        border-color: #dc3545;
      }
    }
  }

  .form-control {
    font-size: 14px;
  }

  .toggle {
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .display {
      flex: 1;
    }
  }

  [class^='col-'] {
    margin-bottom: 0.5rem;
  }

  .buttons {
    margin-top: 20px;
    text-align: right;
  }

  .json-input {
    transition: all .2s ease;
    position: relative;
    opacity: .85;

    svg {
      opacity: 0;
    }

    &.edit-mode {
      opacity: 1;
      svg {
        opacity: 1;
      }
    }
  }

  .option-errors {
    font-size: 12px;
    margin-top: 8px;
    margin: 0 unset;
    border-radius: 0;
    padding: 8px 12px 0;

    .option-errors-title {
      font-weight: 500;
      margin-bottom: 6px !important;
    }
  }

  .readonly {
    .label {
      font-weight: 800;
      text-transform: uppercase;
      font-size: 12px;
      color: var(--color-2);
      margin-bottom: 0px;
    }
  }

  .editabletable-disabled {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-3);
      opacity: .4;
      pointer-events: none;
    }
  }

  .react-date-picker__calendar {
    z-index: 999;
  }

  .react-datepicker-popper {
    z-index: 999;
  }

  .react-date-picker {
    width: 100%;

    .react-date-picker__wrapper {
      padding: 0.2rem 0.75rem;
      padding-right: 0.375rem;
      font-size: 14px;
      color: #212529;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

      .react-date-picker__inputGroup {
        padding: 0;
      }

      input {
        color: #212529;
        margin: 0;
        padding: 0;
      }

      svg {
        opacity: .6;
      }
    }
  }

  .conditionally-disabled {
    pointer-events: none;
    cursor: none !important;
    opacity: 0.45;
  }
}
