.app-header {
  height: 70px;
  padding: 20px 40px !important;
  background: var(--color-1);
  
  .logo {
    height: 30px;
  }

  .links {
    float: right;
    margin-left: 20px;
    color: var(--color-3);
    
    &:hover {
      color: var(--color-white);
    }
  }
}